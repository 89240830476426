// import './_vendor';
// import vars from './_vars';
// import './_functions';
// import './_components';

const burgerEl = document.querySelector('.burger');
const headerEl = document.querySelector('.header');
const navEl = headerEl.querySelector('.nav');
let lastScroll = 0;

const defaultOffset = window.location.pathname == '/' ? document.querySelector('.intro').clientHeight + 100 : headerEl.clientHeight + 100;

const scrollPosition = () => window.pageXOffset || document.documentElement.scrollTop;
const containHide = () => headerEl.classList.contains('hide');

// Fixed header
window.addEventListener('scroll', () => {

  if (scrollPosition() > lastScroll && !containHide() && scrollPosition() > defaultOffset) {
    //scroll down
    headerEl.classList.add('hide');
  } else if (scrollPosition() < lastScroll && containHide()) {
    //scroll up
    headerEl.classList.remove('hide');
    headerEl.classList.add('fixed');
  } else if (scrollPosition() < defaultOffset) {
    headerEl.classList.remove('fixed');
  }

  lastScroll = scrollPosition();
});

// Nav toggle
burgerEl.addEventListener('click', (evt) => {
  headerEl.classList.toggle('show');
});

// Swiper
const swiperIntro = new Swiper('.swiper-intro', {
  loop: true,

  // autoplay: {
  //   delay: 8000,
  // },

  pagination: {
    el: '.swiper-pagination',
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

const thumbsSwiper = new Swiper(".thumbsSwiper", {
  direction: "vertical",
  mousewheel: true,
  spaceBetween: 5,
  slidesPerView: 5.5,
  freeMode: true,
  watchSlidesProgress: true,

  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true,
  },
});

const swiper2 = new Swiper(".mySwiper2", {
  spaceBetween: 10,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  thumbs: {
    swiper: thumbsSwiper,
  },
  pagination: {
    el: '.swiper-pagination',
  },
});

const playVideoEl = document.querySelectorAll('.play-video');

if (playVideoEl) {
  playVideoEl.forEach((el) => {
    el.addEventListener('click', (evt) => {
      evt.target.style.display = 'none';
      evt.target.nextElementSibling ? evt.target.nextElementSibling.style.display = 'none' : null;
      evt.target.closest('div').classList.add('no-pseudo');

      const videoEl = evt.target.closest('div').querySelector('video');
      videoEl.setAttribute('controls', '');
      videoEl.play();
    });
  });
}

const showTab = (elTabBtn) => {
  const elTab = elTabBtn.closest('.tab');
  if (elTabBtn.classList.contains('tab-btn-active')) {
    return;
  }
  const targetId = elTabBtn.dataset.targetId;
  const elTabPane = elTab.querySelector(`.tab-pane[data-id="${targetId}"]`);
  if (elTabPane) {
    const elTabBtnActive = elTab.querySelector('.tab-btn-active');
    elTabBtnActive.classList.remove('tab-btn-active');
    const elTabPaneShow = elTab.querySelector('.tab-pane-show');
    elTabPaneShow.classList.remove('tab-pane-show');
    elTabBtn.classList.add('tab-btn-active');
    elTabPane.classList.add('tab-pane-show');
  }
}

document.addEventListener('click', (e) => {
  if (e.target && !e.target.closest('.tab-btn')) {
    return;
  }
  const elTabBtn = e.target.closest('.tab-btn');
  showTab(elTabBtn);
});

// Show modal
const modalEls = document.querySelectorAll('.modal');
const modalCloseEls = document.querySelectorAll('.modal__close');
const modalLinks = document.querySelectorAll('[data-modal]');
modalLinks.forEach((el) => {
  el.addEventListener('click', (evt) => {
    evt.preventDefault();

    const elementId = el.dataset.modal;
    const modalEl = document.getElementById(elementId);
    modalEl.classList.add('show');
    document.body.classList.add('no-scroll');
  })
});

modalCloseEls.forEach((el) => {
  el.addEventListener('click', (evt) => {
    el.closest('.modal').classList.remove('show');
    document.body.classList.remove('no-scroll');
  });
});

modalEls.forEach((el) => {
  el.addEventListener('click', (evt) => {
    el.classList.remove('show');
    document.body.classList.remove('no-scroll');
  });
});

document.querySelectorAll('.modal__dialog').forEach((el) => {
  el.addEventListener('click', (evt) => {
    evt.stopPropagation();
  });
});
